<template>
  <div>
    <b-row>
      <b-col>
        <b-form
          class="mt-2"
          @submit.prevent
          @submit="editManufacturing(data, manufacturingFile)"
        >
          <b-tabs
            horizontal
            pills
            content-class="col-12 mt-1 mt-md-0"
            nav-wrapper-class="col-12"
            nav-class="nav-left"
          >
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Étape 1</span>
              </template>
              <manufacturing-step-1 :data="data" />
            </b-tab>
            <b-tab type="submit">
              <template #title>
                <feather-icon
                  icon="ActivityIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Étape 2</span>
              </template>
              <manufacturing-step-2 :data="data" />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="BluetoothIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Étape 3</span>
              </template>
              <manufacturing-step-3 :data="data" />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Étape 4</span>
              </template>
              <manufacturing-step-4
                :data="data"
              />
            </b-tab>

            <b-tab>
              <template #title>
                <feather-icon
                  icon="FilePlusIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Étape 5</span>
              </template>
              <manufacturing-step-5
                :data="data"
                :manufacturing-file.sync="manufacturingFile"
                :actual-files="files"
              />
            </b-tab>
          </b-tabs>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-2 mr-1"
            >
              Sauvegarder
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              class="mt-2"
              :to="{ name: 'manufacturings' }"
            >
              Annuler
            </b-button>
          </b-col>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol, BButton, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ManufacturingStep1 from './ManufacturingStep1.vue'
import ManufacturingStep2 from './ManufacturingStep2.vue'
import ManufacturingStep3 from './ManufacturingStep3.vue'
import ManufacturingStep4 from './ManufacturingStep4.vue'
import ManufacturingStep5 from './ManufacturingStep5.vue'
/* eslint no-underscore-dangle: 0 */
/* eslint no-param-reassign: 0 */

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    BForm,
    ManufacturingStep1,
    ManufacturingStep2,
    ManufacturingStep3,
    ManufacturingStep4,
    ManufacturingStep5,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
      manufacturingFile: null,
      files: [],
    }
  },
  mounted() {
    store.dispatch('manufacturing/findManufacturing', this.$router.currentRoute.params.id)
      .then(response => {
        this.data = response
        this.$store.dispatch('file/fetchFile', { manufacturingId: response.id })
          .then(res => {
            if (res.length > 0) {
              res.forEach(file => {
                const projectImage = Buffer.from(file.content).toString('base64')
                file.content = projectImage
                this.files.push(file)
              })
            }
          })
      })
  },
  methods: {
    async editManufacturing(data, manufacturingFile) {
      if (manufacturingFile !== null) {
        await store.dispatch('file/addFile', manufacturingFile)
      }
      store.dispatch('manufacturing/updateManufacturing', { manufacId: data.id, body: data })
        .then(() => this.$router.push({ name: 'manufacturings-view', params: { id: data.id } }))
    },
    async createAndDownloadBlobFile(body, filename, extension = 'png') {
      const result = await this.base64ToArrayBuffer(body)
      const blob = new Blob([result])
      const fileName = `${filename}.${extension}`
      const link = document.createElement('a')
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64)
      const bytes = new Uint8Array(binaryString.length)
      return bytes.map((byte, i) => binaryString.charCodeAt(i))
    },
  },
}
</script>
